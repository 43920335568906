import React from 'react';

import LoaderIconSvg from '#assets/cut-ellipse.svg';

import css from './LoadingScreen.module.scss';

export const LoadingScreen = () => {
  return (
    <div className={css.loadingLayout}>
      <LoaderIconSvg className={css.loadingIcon} alt="loading" />
    </div>
  );
};

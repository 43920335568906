import React from 'react';

import { ErrorScreen } from '#components/AppLayout/ErrorScreen/ErrorScreen';
import { LoadingScreen } from '#components/AppLayout/LoadingScreen/LoadingScreen';

import { root } from '#root';

fetch('/env.json')
  .then((response) => response.json())
  .then((data) => {
    window.FETCHED_ENV_DATA = data;
  })
  .catch((error) => {
    console.error('There is no env.json:', error);
  })
  .finally(() => {
    let attemptCount = 0;

    const loadChunk = () => {
      root.render(<LoadingScreen />);
      import(/* webpackChunkName: "app" */ '#index').catch((error) => {
        console.error(error);
        attemptCount++;
        if (attemptCount < 3) {
          setTimeout(loadChunk, 3000);
        } else {
          root.render(<ErrorScreen />);
        }
      });
    };

    loadChunk();
  });

import classNames from 'classnames';
import React from 'react';

import ErrorScreenSvg from '#assets/error-screen.svg';
import LogoErrorScreenSvg from '#assets/logo-error-screen.svg';

import css from './ErrorScreen.module.scss';

export const ErrorScreen = () => {
  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={css.layoutErrorScreen}>
      <LogoErrorScreenSvg className={css.logoErrorScreenIcon} />
      <ErrorScreenSvg className={css.errorScreenIcon} />
      <div className={css.errorScreenTextContainer}>
        <p className={css.errorScreenTitle}>Session was interrupted</p>
        <p className={css.errorScreenDescription}>Please check your network connection and try again</p>
      </div>
      <button className={classNames(css.backToLoginButton, 'n2pc-btn-primary')} onClick={handleReloadPage}>
        Try again
      </button>
    </div>
  );
};
